@use '@/styles/utils/mixins.scss' as *;

.desktopNavigation {
  @include mob() {
    display: none;
  }

  .verticalTabWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-top: 3.75rem;

    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }

    .tabNavigation {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: flex-start;

      @include tab() {
        flex-direction: row;
        gap: 1.25rem;
        overflow-x: auto;
        padding-bottom: 0.5rem;
        margin-top: 0rem;
      }

      .tabButton {
        display: flex;
        align-items: baseline;
        font-size: 1.5rem;
        gap: 0.25rem;
        color: $neutral07;
        border: none;
        background: none;
        cursor: pointer;
        text-align: left;
        width: fit-content;
        position: relative;
        font-weight: 500;

        h3 {
          font-weight: normal;
          font-size: inherit;
        }

        @include tab() {
          font-size: 1rem;
          text-wrap: nowrap;
          font-weight: 400;
        }

        .productType {
          font-size: 0.875rem;

          @include tab() {
            font-size: 1rem;
          }
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 0.063rem;
          width: 0;
          background-color: $primary;
          transition: width 0.3s;
        }

        &:hover::before,
        &:focus::before,
        &:active::before,
        &.active::before {
          width: 100%;
          transition: width 0.3s;
        }

        &:active,
        &:focus,
        &:hover,
        &.active {
          color: $primary;
        }

        &.active {
          @include tab() {
            font-weight: bold;
          }
        }
      }
    }

    .tabContainer {
      border-top: none;
      width: 100%;
      background-color: transparent;
      grid-column: span 2;

      @include tab() {
        grid-column: span 1;
      }

      @include mob() {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
      }

      :global {
        .employeeName {
          text-wrap: nowrap;
        }
        .employeeTitle {
          width: fit-content;
          text-align: right;
        }
      }
    }

    .tabContent {
      display: none;

      .companyDetails {
        display: flex;
        flex-direction: row;
        gap: 4rem;
        justify-content: space-between;
        border-radius: 0.625rem;
        border: 0.063rem solid var(--Neutral-04, #d3d3d3);
        padding: 1.25rem 1.875rem;

        @include tab() {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }

      &.activeContent {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
      }
    }

    .tabDrawerHeading {
      display: none;
    }
  }
}

.mobileNavigation {
  display: none;

  @include mob() {
    display: block;
  }
}
